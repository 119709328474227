<template>
    <section class="content-wrapper">
        <div v-if="$store.state.isAuthenticated">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <!-- <img src="../assets/images/logo-beekeeper.png"> -->
                            </div>
                            <h4 class="text-center mt-4">Bienvenue sur Beekeeper974</h4>
                            <h3 class="font-weight-light text-center">Content de vous revoir {{$store.state.firstname}} {{$store.state.lastname}}!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- si connecté -->
        <div v-else class="row">
            <div class="col-md-6 offset-md-3">
                <div class="card">
                    <div class="card-body">
                        <div class="auth-form-light text-left p-5">
                            <div class="brand-logo text-center">
                            <!-- <img src="../assets/images/logo-beekeeper.png"> -->
                            </div>
                            <h4 class="text-center mt-4">Bienvenue sur Beekeeper974</h4>
                            <h6 class="font-weight-light text-center">Se connecter pour continuer.</h6>
                            <form class="pt-3" @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <input type="text" v-model="userName" class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Nom d'utilisateur ou adresse mail">
                            </div>
                            <div class="form-group">
                                <input type="password" v-model="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Mot de passe">
                            </div>
                            <div class="mt-3">
                                <button class="btn btn-block  btn-lg font-weight-medium auth-form-btn" style="background:#0055a4; color:white"><span>Se connecter &nbsp; <span v-show="loadingBar" class="circle-loader ml-2"></span></span></button>
                            </div>
                            <div class="my-2 d-flex justify-content-between align-items-center">
                                <div class="form-check">
                                <label class="form-check-label text-muted">
                                    <input type="checkbox" class="form-check-input">
                                    Rester connecté
                                    <i class="input-helper"></i>
                                </label>
                                </div>
                                <a href="javascript:void(0);" class="auth-link text-black">
                                    <router-link :to="{name: 'forgotPassword'}">
                                    Mot de passe oublié
                                        
                                    </router-link>
                                    </a>
                            </div>
                            <!-- <div class="mb-2">
                                <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                                <i class="mdi mdi-facebook mr-2"></i>Se connecter avec facebook
                                </button>
                            </div> -->
                            <div class="text-center mt-4 font-weight-light">
                                Vous n'avez pas de compte? <router-link to="/auth-pages/register" class="text-primary">Créer</router-link>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const axios= require('axios').default
export default {
    name:'login',
    data () {
        return {
            userName:'',
            password:'',
            loadingBar:false
        }
    },
    methods: {
        // async handleSubmit () {
        //     const response = await axios.post('https://sites.comolapapaya.com/login',
        // { 
        //     username : this.userName, 
        //     password : this.password
        // },
        // {
        //     withCredentials: true,
        // })
        // localStorage.setItem('token', response.data.token)
        
        // //   localStorage.setItem('pseudo',response.data.pseudo)
        // //   localStorage.setItem('roles',response.data.roles)
        // //   this.$router.push({name: 'profil'})
        // //   this.$router.go()
        // }

        async handleSubmit () {
            this.loadingBar= true
            await axios.post('https://sites.comolapapaya.com/login',
            {
            username : this.userName, 
            password : this.password 
            },
            {
                withCredentials: true
            })
            .then(resLog => {
                // console.log({resLog})
                localStorage.setItem('token', resLog.data.token)
                localStorage.setItem('pseudo',resLog.data.pseudo)
                this.$router.push({name: 'dashboard'})
                // this.$router.go()
                })
            .catch(errLog => {
                console.log({errLog})
                axios.post('https://sites.comolapapaya.com/login',
                {
                username : this.userName, 
                password : this.password 
                },
                {
                    withCredentials: true
                })
                .then(secondeResLog => {
                    // console.log({secondeResLog})
                    localStorage.setItem('token', secondeResLog.data.token)
                    localStorage.setItem('pseudo',secondeResLog.data.pseudo)
                    this.$router.push({name: 'dashboard'})
                    // this.$router.go()
                    })
                .catch(secondErrLog => {
                    // console.log({secondErrLog})
                    console.log('status',secondErrLog.response.status)
                    if(secondErrLog.response.status=="401"){
                        alert('Le nom d\'utilisateur ou le mot de passe comporte une erreur!')
                        this.loadingBar= false
                    }
                    else{
                        console.log('Nom d\'utilisateur et mot de passe validés');
                    }
                }
                )
                }
            )
            }
    },
    mounted() {
        // axios.post('https://sites.comolapapaya.com/login',
        // {
        //    username : 'User', 
        // password : 'password' 
        // },
        // {
        //     withCredentials: true
        // })
        // .then(resLog => console.log({resLog}))
        // .catch(() =>console.log('erreur get login at mounted'))
    }
}
</script>